import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';
import { useToast } from '../../Contexts/ToastContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import api from '../Common/axiosSetup';

const SignOut = () => {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const { setIsAuthenticated } = useAuth();

    const handleSignOut = async () => {
        try {
            if (process.env.REACT_APP_ENV === 'development') {
                console.log('Signing out...');
            }

            // Get the refresh token from localStorage
            const refreshToken = localStorage.getItem('refreshToken');

            if (refreshToken) {
                // Make API call to your backend logout route
                await api.post('/api/auth/logout', { refreshToken });

                // Remove tokens from localStorage
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');

                // Update authentication state
                setIsAuthenticated(false);

                addToast('You have been successfully signed out. Good job being responsible!', {
                    autoHide: true,
                    delay: 15000,
                    color: 'success',
                });

                // Navigate to the home page after sign out
                navigate('/');
            } else {
                throw new Error('No refresh token found');
            }
        } catch (error) {
            addToast('Error signing out. Please try again.', { autoHide: true, delay: 15000, color: 'danger' });
            if (process.env.REACT_APP_ENV === 'development') {
                console.error('Error signing out:', error);
            }
        }
    };

    return (
        <li>
            <Link to="#" className="m-link" onClick={handleSignOut}>
                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                <span>Sign Out</span>
            </Link>
        </li>
    );
};

export default SignOut;