// Import necessary dependencies
import React, { createContext, useContext, useState, useCallback, useEffect, useReducer } from 'react';
import ReactDOMServer from 'react-dom/server';
import parse, { domToReact } from 'html-react-parser';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Create a context for the toast functionality
const ToastContext = createContext();

// Custom hook to use the ToastContext
export const useToast = () => useContext(ToastContext);

// Reducer function to manage toast state
const toastReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TOAST':
            return [...state, action.toast];
        case 'REMOVE_TOAST':
            return state.filter(toast => toast.id !== action.id);
        default:
            return state;
    }
};

// Component to render the toast container and individual toasts
const ToastContainer = ({ toasts, removeToast }) => {
    const [progressMap, setProgressMap] = useState({});

    // Effect to handle toast timers and progress
    useEffect(() => {
        const timers = toasts.map(toast => {
            if (toast.autoHide) {
                const interval = setInterval(() => {
                    const now = Date.now();
                    const elapsed = now - toast.timestamp;
                    const remaining = toast.delay - elapsed;
                    const progress = Math.max(0, (remaining / toast.delay) * 100);

                    setProgressMap(prev => ({
                        ...prev,
                        [toast.id]: progress
                    }));

                    if (remaining <= 0) {
                        clearInterval(interval);
                        removeToast(toast.id);
                    }
                }, 100);

                return interval;
            }
            return null;
        });

        // Cleanup function to clear intervals
        return () => {
            timers.forEach(timer => timer && clearInterval(timer));
        };
    }, [toasts, removeToast]);

    // Options for parsing HTML content
    const parseOptions = {
        replace: ({ name, attribs, children }) => {
            if (name === 'a' && attribs.href) {
                return <Link to={attribs.href}>{domToReact(children, parseOptions)}</Link>;
            }
        }
    };

    // Render the toast container and individual toasts
    return (
        <div className="toast-container position-fixed top-0 end-0 p-3">
            {toasts.map(toast => (
                <div key={toast.id} className={`toast show position-relative`} role="alert" aria-live="assertive" aria-atomic="true">
                    <div className={`toast-header ${toast.color ? `bg-${toast.color} text-white` : ''}`}>
                        <strong className="me-auto">Notification</strong>
                        {toast.requireAck && (
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => removeToast(toast.id)}></button>
                        )}
                        {toast.autoHide && (
                            <ProgressBar
                                now={progressMap[toast.id] || 100}
                                variant={toast.color}
                                className="position-absolute bottom-0 start-0 end-0"
                                style={{ height: '4px' }}
                            />
                        )}
                    </div>
                    <div className="toast-body text-dark">
                        {parse(toast.message, parseOptions)}
                        <p className="mb-0 mt-2">
                            <small className="text-muted">{new Date(toast.timestamp).toLocaleTimeString()}</small>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

// Provider component for the ToastContext
export const ToastProvider = ({ children }) => {
    // Initialize toasts state with persisted toasts from localStorage
    const [toasts, dispatch] = useReducer(toastReducer, [], () => {
        const savedToasts = localStorage.getItem('toasts');
        if (savedToasts) {
            const parsedToasts = JSON.parse(savedToasts);
            return parsedToasts.map(toast => ({
                ...toast,
                message: parse(toast.message)
            }));
        }
        return [];
    });

    // Function to add a new toast
    const addToast = useCallback((message, options = {}) => {
        const id = `${Date.now()}-${Math.floor(Math.random() * 100000)}`;
        const timestamp = Date.now();
        const newToast = { id, message: ReactDOMServer.renderToString(message), timestamp, ...options };
        dispatch({ type: 'ADD_TOAST', toast: newToast });
    }, []);

    // Function to remove a toast
    const removeToast = useCallback(id => {
        dispatch({ type: 'REMOVE_TOAST', id });
    }, []);

    // Effect to persist toasts in localStorage
    useEffect(() => {
        const serializableToasts = toasts.map(toast => ({
            ...toast,
            message: ReactDOMServer.renderToString(toast.message)
        }));
        localStorage.setItem('toasts', JSON.stringify(serializableToasts));
    }, [toasts]);

    // Render the provider with its children and the ToastContainer
    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            <ToastContainer toasts={toasts} removeToast={removeToast} />
        </ToastContext.Provider>
    );
};
