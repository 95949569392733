import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';

const VerificationInput = ({ onVerify, loading, message, numberOfFields = 6, clear }) => {
    const [verificationCode, setVerificationCode] = useState(Array(numberOfFields).fill(''));
    const inputRefs = useRef([]);

    // Initialize refs for each input field
    useEffect(() => {
        inputRefs.current = Array(numberOfFields)
            .fill()
            .map((_, i) => inputRefs.current[i] || React.createRef());
    }, [numberOfFields]);

    // Clear verification code and reset focus when the clear prop changes
    useEffect(() => {
        if (clear) {
            setVerificationCode(Array(numberOfFields).fill(''));
            if (inputRefs.current[0]?.current) {
                inputRefs.current[0].current.focus();
            }
        }
    }, [clear, numberOfFields]);

    // Ensure first input is focused when the component first mounts
    useEffect(() => {
        if (inputRefs.current[0]?.current) {
            inputRefs.current[0].current.focus();
        }
    }, []);

    // Focus next input after state change
    useEffect(() => {
        const nextEmptyIndex = verificationCode.findIndex((value) => value === '');
        if (nextEmptyIndex !== -1 && inputRefs.current[nextEmptyIndex]?.current) {
            inputRefs.current[nextEmptyIndex].current.focus();
        }
    }, [verificationCode]);

    const handleInputChange = (index, event) => {
        const newInputs = [...verificationCode];
        newInputs[index] = event.target.value.slice(0, 1);
        setVerificationCode(newInputs);

        if (event.target.value && index < verificationCode.length - 1) {
            inputRefs.current[index + 1]?.current?.focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && verificationCode[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1]?.current?.focus();
            }
        }
    };

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData('text').slice(0, numberOfFields);
        const newInputs = paste.split('');
        setVerificationCode(newInputs);

        const nextInputIndex = newInputs.length < verificationCode.length ? newInputs.length : verificationCode.length - 1;
        if (inputRefs.current[nextInputIndex]) {
            inputRefs.current[nextInputIndex]?.current?.focus();
        }

        event.preventDefault();
    };

    const handleInputFocus = (index) => {
        inputRefs.current[index]?.current?.select();
    };

    const handleVerify = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const token = verificationCode.join('');
        onVerify(token);
    };

    return (
        <Form onSubmit={handleVerify}>
            {message && <p>{message}</p>}
            <div className="d-flex justify-content-between mb-4">
                {verificationCode.map((value, index) => (
                    <Form.Control
                        key={index}
                        ref={inputRefs.current[index]}
                        type="text"
                        className="form-control form-control-lg text-center"
                        style={{ width: '12%' }}
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleInputChange(index, e)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        onFocus={() => handleInputFocus(index)}
                        onPaste={handlePaste}
                        required
                    />
                ))}
            </div>
            <Button type="button" onClick={handleVerify} variant="outline-secondary" className="w-100" disabled={loading}>
                {loading ? (
                    <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Verifying...
                    </>
                ) : (
                    "Verify"
                )}
            </Button>
        </Form>
    );
};

export default VerificationInput;