// Import necessary dependencies and components
import React, { useState, useEffect } from 'react';
import api from '../../Component/Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { Card, Form, Button } from 'react-bootstrap';
import VerificationInput from '../Common/VerificationInput';

// Component for changing user password
const ChangePassword = () => {
    // State variables for password fields
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    // State variables for MFA handling
    const [isMFAMode, setIsMFAMode] = useState(false);
    const [clearVerificationInput, setClearVerificationInput] = useState(false);
    const [mfaRequiredMessage, setMfaRequiredMessage] = useState('');
    
    // Toast notification hook
    const { addToast } = useToast();
    
    // Loading state
    const [loading, setLoading] = useState(false);

    // Effect to reset verification input when MFA mode is disabled
    useEffect(() => {
        if (!isMFAMode) {
            setClearVerificationInput(false);
        }
    }, [isMFAMode]);

    // Function to handle password change
    const handleChangePassword = async () => {
        // Check if new password and confirm password match
        if (newPassword !== confirmPassword) {
            addToast('Error: New password and confirm password do not match.', {
                autoHide: true,
                delay: 5000,
                color: 'danger',
                requireAck: true
            });
            return;
        }

        try {
            setLoading(true);
            const accessToken = localStorage.getItem('accessToken');
            const mfaToken = localStorage.getItem('mfaToken');

            // Send password change request to the API
            await api.post('/api/user/profile/change-password', {
                old_password: currentPassword,
                new_password: newPassword
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'x-mfa-token': mfaToken || ''
                }
            });

            // Show success message
            addToast('Password updated successfully!', {
                autoHide: true,
                delay: 5000,
                color: 'success'
            });

            // Clear the form inputs after successful password update
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setIsMFAMode(false);
        } catch (error) {
            // Handle MFA requirement
            if (error.response?.data?.error?.code === 'MFA_REQUIRED') {
                setIsMFAMode(true);
                setMfaRequiredMessage(error.response.data.error.message);
            } else {
                // Handle other errors
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
                addToast(`Error modifying password: ${errorMessage}`, {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true
                });
                console.error('Error updating password:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        handleChangePassword();
    };

    // Function to handle MFA verification
    const handleMFA = async (token) => {
        try {
            setLoading(true);
            const accessToken = localStorage.getItem('accessToken');
            
            // Verify TOTP code
            const response = await api.post('/api/auth/verify-totp', { totpCode: token }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });

            // Store MFA token and reset MFA mode
            const { mfaToken } = response.data;
            localStorage.setItem('mfaToken', mfaToken);
            setIsMFAMode(false);
            setMfaRequiredMessage('');

            // Show success message
            addToast('MFA verified successfully. Please proceed.', {
                autoHide: true,
                delay: 5000,
                color: 'success',
            });

            // Retry changing password after successful MFA verification
            handleChangePassword();
        } catch (error) {
            // Handle MFA verification error
            const errorMessage = error.response?.data?.error?.message || 'Invalid MFA token. Please try again.';
            addToast(`Error verifying MFA: ${errorMessage}`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">Change Password</Card.Title>
                {isMFAMode ? (
                    // MFA verification form
                    <>
                        <Form.Group className="mb-3" controlId="formMFA">
                            <Form.Label>Multi-Factor Authentication</Form.Label>
                            <VerificationInput
                                numberOfFields={6}
                                onVerify={handleMFA}
                                clear={clearVerificationInput}
                                message={mfaRequiredMessage}
                            />
                        </Form.Group>
                        <Button variant="outline-secondary" onClick={() => setIsMFAMode(false)}>
                            Cancel MFA
                        </Button>
                    </>
                ) : (
                    // Password change form
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formCurrentPassword">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formConfirmPassword">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    Changing...
                                </>
                            ) : (
                                'Change Password'
                            )}
                        </Button>
                    </Form>
                )}
            </Card.Body>
        </Card>
    );
};

export default ChangePassword;