import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const UserProfileMenu = () => (
    <Nav className="flex-column nav-pills">
        <Nav.Item>
            <Nav.Link as={NavLink} to="/profile/overview" className={({ isActive }) => isActive ? "active" : ""}>Profile Overview</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={NavLink} to="/profile/change-email" className={({ isActive }) => isActive ? "active" : ""}>Change Email</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={NavLink} to="/profile/change-password" className={({ isActive }) => isActive ? "active" : ""}>Change Password</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link as={NavLink} to="/profile/configure-totp" className={({ isActive }) => isActive ? "active" : ""}>Configure MFA</Nav.Link>
        </Nav.Item>
    </Nav>
);

export default UserProfileMenu;
