// Import necessary dependencies
import React, { useState } from 'react';
import { useAuth } from '../../Contexts/AuthContext';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBell,
    faCog,
    faExclamationTriangle,
    faCheckCircle,
    faUserCircle,
    faServer,
    faDatabase,
    faNetworkWired
} from '@fortawesome/free-solid-svg-icons';

function Header(props) {
    const { onModalOpen, GotoChangeMenu } = props;
    const { isAuthenticated, authCheckComplete, authenticatedEmail } = useAuth();
    
    // State for managing collapse and sidebar
    const [collapse, setCollapse] = useState(false);
    const [collapsebig, setCollapsebig] = useState(false);
    const [sidebarMini, setSidebarMini] = useState(false);

    /**
     * Toggle the common sidebar visibility
     */
    const closeCommanSidebar = () => {
        var sidebar = document.getElementById('commansidebar')
        if (sidebar) {
            if (sidebar.classList.contains('d-none')) {
                sidebar.classList.remove('d-none')
            } else {
                sidebar.classList.add('d-none')
            }
        }
    }

    return (
        <div className="header">
            <nav className="navbar py-4">
                <div className="container-xxl">
                    <div className="h-right d-flex align-items-center order-1">
                        {isAuthenticated && (
                            <>
                                {/* Notifications dropdown */}
                                <Dropdown className="dropdown notifications">
                                    <Dropdown.Toggle as='s' className="nav-link dropdown-toggle pulse" role="button"
                                                     data-bs-toggle="dropdown">
                                        <FontAwesomeIcon icon={faBell} size="lg"/>
                                        <span className="pulse-ring"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu id="NotificationsDiv"
                                                   className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0">
                                        <div className="card border-0 w380">
                                            {/* Notification header */}
                                            <div className="card-header border-0 p-3">
                                                <h5 className="mb-0 font-weight-light d-flex justify-content-between">
                                                    <span>Notifications</span>
                                                    <span className="badge text-white">06</span>
                                                </h5>
                                            </div>
                                            {/* Notification body */}
                                            <div className="tab-content card-body">
                                                <div className="tab-pane fade show active">
                                                    <ul className="list-unstyled list mb-0">
                                                        {/* System Alert notification */}
                                                        <li className="py-2 mb-1 border-bottom">
                                                            <a className="d-flex">
                                                                <FontAwesomeIcon icon={faExclamationTriangle} className="avatar rounded-circle bg-warning text-white" />
                                                                <div className="flex-fill ms-2">
                                                                    <p className="d-flex justify-content-between mb-0 ">
                                                                        <span className="font-weight-bold">System Alert</span>
                                                                        <small>2MIN</small>
                                                                    </p>
                                                                    <span className="">High CPU usage detected <span className="badge bg-danger">Critical</span></span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        {/* Task Completed notification */}
                                                        <li className="py-2 mb-1 border-bottom">
                                                            <a className="d-flex">
                                                                <FontAwesomeIcon icon={faCheckCircle} className="avatar rounded-circle bg-success text-white" />
                                                                <div className="flex-fill ms-2">
                                                                    <p className="d-flex justify-content-between mb-0 ">
                                                                        <span className="font-weight-bold">Task Completed</span>
                                                                        <small>13MIN</small>
                                                                    </p>
                                                                    <span className="">Backup process finished successfully</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        {/* New User notification */}
                                                        <li className="py-2 mb-1 border-bottom">
                                                            <a className="d-flex">
                                                                <FontAwesomeIcon icon={faUserCircle} className="avatar rounded-circle bg-info text-white" />
                                                                <div className="flex-fill ms-2">
                                                                    <p className="d-flex justify-content-between mb-0 ">
                                                                        <span className="font-weight-bold">New User</span>
                                                                        <small>1HR</small>
                                                                    </p>
                                                                    <span className="">New user registered: John Doe</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        {/* Server Update notification */}
                                                        <li className="py-2 mb-1 border-bottom">
                                                            <a className="d-flex">
                                                                <FontAwesomeIcon icon={faServer} className="avatar rounded-circle bg-primary text-white" />
                                                                <div className="flex-fill ms-2">
                                                                    <p className="d-flex justify-content-between mb-0 ">
                                                                        <span className="font-weight-bold">Server Update</span>
                                                                        <small>13MIN</small>
                                                                    </p>
                                                                    <span className="">Server patch 2.4.5 applied successfully</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        {/* Database Alert notification */}
                                                        <li className="py-2 mb-1 border-bottom">
                                                            <a className="d-flex">
                                                                <FontAwesomeIcon icon={faDatabase} className="avatar rounded-circle bg-secondary text-white" />
                                                                <div className="flex-fill ms-2">
                                                                    <p className="d-flex justify-content-between mb-0 ">
                                                                        <span className="font-weight-bold">Database Alert</span>
                                                                        <small>1HR</small>
                                                                    </p>
                                                                    <span className="">Database approaching storage limit</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        {/* Network Issue notification */}
                                                        <li className="py-2">
                                                            <a className="d-flex">
                                                                <FontAwesomeIcon icon={faNetworkWired} className="avatar rounded-circle bg-danger text-white" />
                                                                <div className="flex-fill ms-2">
                                                                    <p className="d-flex justify-content-between mb-0 ">
                                                                        <span className="font-weight-bold">Network Issue</span>
                                                                        <small className="">1DAY</small>
                                                                    </p>
                                                                    <span className="">Intermittent network connectivity detected</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* View all notifications link */}
                                            <a className="card-footer text-center border-top-0"> View all notifications</a>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                        )}
                        <div style={{ width: '30px', display: 'inline-block' }}></div>
                        {/* Settings icon */}
                        <div className="setting">
                            <a className="setting-link" data-bs-toggle="modal" data-bs-target="#Settingmodal"
                               onClick={onModalOpen}>
                                <FontAwesomeIcon icon={faCog} size="lg"/>
                            </a>
                        </div>
                    </div>
                    {/* Mobile menu toggle button */}
                    <button className="navbar-toggler p-0 border-0 menu-toggle order-3" type="button" onClick={() => {
                        var sidebar = document.getElementById('mainsidemenu')
                        if (sidebar) {
                            if (sidebar.classList.contains('open')) {
                                sidebar.classList.remove('open')
                            } else {
                                sidebar.classList.add('open')
                            }
                        }
                    }}>
                        <span className="fa fa-bars"></span>
                    </button>
                    {/* Placeholder for additional content */}
                    <div className="order-0 col-lg-4 col-md-4 col-sm-4 col-12 mb-3 mb-sm-0 d-flex align-items-center">
                        &nbsp;
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;