import React from 'react';
import {Link} from "react-router-dom";
import LogoBlack from "../../assets/images/base_textlogo_transparent_background.png";
import LogoWhite from "../../assets/images/base_textlogo_transparent_background.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog
} from '@fortawesome/free-solid-svg-icons';

// AuthHeader component for displaying the header in authentication pages
function AuthHeader(props) {
    const {onModalOpen} = props;

    // Function to determine which logo to use based on the current theme
    const getLogo = () => {
        const mode = document.documentElement.getAttribute("data-theme");
        if (mode === 'light') {
            return LogoBlack;
        } else {
            return LogoWhite;
        }
    }

    return (
        <div className="container-xxl">
            <div className="row align-items-start auth-header-row">
                {/* Logo section */}
                <div className="col">
                    <Link to={"/"} className="d-flex align-item-center auth-logo">
                        <img className="logo-image" src={getLogo()} alt="FieldWarden Logo"/>
                    </Link>
                </div>
                {/* Settings icon section */}
                <div className="col-auto">
                    <div className="d-flex align-items-center setting ms-2 mt-1 settings-icon">
                        {/* Settings modal trigger */}
                        <a href="#!" data-bs-toggle="modal" data-bs-target="#Settingmodal" onClick={onModalOpen}>
                            <FontAwesomeIcon icon={faCog} size="lg" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthHeader;