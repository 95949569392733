// Import necessary dependencies and hooks
import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import api from '../Component/Common/axiosSetup';
import { useToast } from './ToastContext';
import { useNavigate } from 'react-router-dom';

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap the app and provide authentication context
export const AuthProvider = ({ children }) => {
    // State variables for authentication
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authenticatedEmail, setAuthenticatedEmail] = useState('');
    const [authCheckComplete, setAuthCheckComplete] = useState(false);
    
    // Hooks for toast notifications and navigation
    const { addToast } = useToast();
    const prevIsAuthenticated = useRef(isAuthenticated);
    const navigate = useNavigate();

    // Handle session timeout
    const handleSessionTimeout = useCallback(() => {
        // Display a toast notification for session timeout
        addToast(
            <span>Your session has timed out. Please sign in again.</span>,
            {
                requireAck: true,
                autoHide: false,
                color: 'warning'
            }
        );
        // Clear tokens from local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // Update authentication state
        setIsAuthenticated(false);
        setAuthenticatedEmail('');
        // Redirect to login page
        navigate('/login');
    }, [addToast, navigate]);

    // Check authentication state by calling the backend API
    const checkAuthState = useCallback(async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            setIsAuthenticated(false);
            setAuthCheckComplete(true);
            return;
        }

        try {
            // Check session validity
            const response = await api.post('/api/auth/check-session', null, {
                headers: { Authorization: `Bearer ${accessToken}` }
            });

            const { user } = response.data;
            setAuthenticatedEmail(user.email);
            setIsAuthenticated(true);
        } catch (error) {
            // If the access token is invalid or expired, try to refresh it
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                try {
                    // Attempt to refresh the token
                    const refreshResponse = await api.post('/api/auth/refresh-token', { refreshToken });
                    const { accessToken: newAccessToken } = refreshResponse.data;

                    // Store the new access token and retry the session check
                    localStorage.setItem('accessToken', newAccessToken);
                } catch (refreshError) {
                    // If refresh fails, handle session timeout
                    handleSessionTimeout();
                }
            } else {
                handleSessionTimeout();
            }
        } finally {
            setAuthCheckComplete(true);
        }
    }, [handleSessionTimeout]);

    // Effect to perform initial auth state check
    useEffect(() => {
        checkAuthState();
    }, [checkAuthState]);

    // Effect to set up interval for periodic auth state checks
    useEffect(() => {
        // Set up interval to check auth state only if the user is authenticated
        if (isAuthenticated) {
            const interval = setInterval(() => {
                checkAuthState();
            }, 60000);  // Check every 60 seconds

            return () => clearInterval(interval);  // Cleanup interval on component unmount
        }
    }, [isAuthenticated, checkAuthState]);

    // Effect to update the previous authentication state ref
    useEffect(() => {
        prevIsAuthenticated.current = isAuthenticated;
    }, [isAuthenticated]);

    // Provide the authentication context to children components
    return (
        <AuthContext.Provider value={{ isAuthenticated, authenticatedEmail, authCheckComplete, checkAuthState, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};