// Import necessary dependencies and components
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../../Contexts/AuthContext';
import api from '../Common/axiosSetup';
import { Form, Button, Card } from 'react-bootstrap';
import { useToast } from '../../Contexts/ToastContext';
import VerificationInput from '../Common/VerificationInput';

/**
 * Login component for user authentication
 * Handles both regular login and Multi-Factor Authentication (MFA)
 */
function Login() {
    // Hooks for navigation and authentication
    const navigate = useNavigate();
    const { isAuthenticated, authCheckComplete, authenticatedEmail } = useAuth();

    // State variables for form inputs and UI control
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [totpCode, setTotpCode] = useState(Array(6).fill(''));
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [mfaRequired, setMfaRequired] = useState(false);
    const [mfaSessionToken, setMfaSessionToken] = useState('');
    const [clearVerificationInput, setClearVerificationInput] = useState(false);

    /**
     * Handles the form submission for login
     * @param {Event} event - The form submission event
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess(false);
        setMessage('');
        setLoading(true);

        try {
            const response = await api.post(`/api/auth/login`, {
                email,
                password
            });

            const { accessToken, refreshToken, mfaRequired, mfaSessionToken } = response.data;

            if (mfaRequired) {
                // Handle MFA requirement
                setMfaRequired(true);
                setMfaSessionToken(mfaSessionToken);
                setMessage('Multi-Factor Authentication is required for your account. Please enter your TOTP code from your authenticator app.');
            } else {
                // Handle successful login
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                setSuccess(true);
                setMessage('Login successful! Redirecting to your dashboard...');
                setTimeout(() => navigate('/dashboard'), 2000);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles the verification of MFA token
     * @param {string} token - The MFA token entered by the user
     */
    const handleVerifyMFA = async (token) => {
        setLoading(true);
        try {
            const response = await api.post('/api/auth/verify-totp-login', { totpCode: token, mfaSessionToken });
            
            const { accessToken, refreshToken } = response.data;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            setSuccess(true);
            setMessage('Login successful! Redirecting to your dashboard...');
            setTimeout(() => navigate('/dashboard'), 2000);
        } catch (error) {
            handleError(error);
            setClearVerificationInput(true);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles and displays errors during login process
     * @param {Error} error - The error object
     */
    const handleError = (error) => {
        if (error.response) {
            const { status } = error.response;
            const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred. Contact support@fieldwarden.io if this error persists.';

            if (status === 401) {
                setError('Login failed. Please fix the error below and try again.\n\n' + errorMessage);
            } else if (status === 404) {
                setError('Service currently unavailable. Please try again later.');
            } else if (status >= 500) {
                setError('Login failed. Please try again.\n\n' + errorMessage);
            } else {
                setError('Login failed. Please fix the error below and try again.\n\n' + errorMessage);
            }
        } else if (error.request) {
            setError('No response from the server. Please check your network connection. Contact support@fieldwarden.io if this error persists.');
        } else {
            setError('Error: ' + error.message + '\n\nPlease contact support@fieldwarden.io if this error persists.');
        }
    };

    // Render the login form
    return (
        <Card className="mx-auto mt-5" style={{ maxWidth: '400px' }}>
            <Card.Body>
                <Card.Title className="text-center mb-4">Sign In</Card.Title>
                {!mfaRequired ? (
                    // Regular login form
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <Form.Label>Email Address *</Form.Label>
                            <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <Form.Label>Password *</Form.Label>
                            <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                        </div>
                        <Button type="submit" disabled={loading} className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                            {loading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    Loading...
                                </>
                            ) : (
                                "Log In"
                            )}
                        </Button>
                    </Form>
                ) : (
                    // MFA verification form
                    <Form>
                        <Form.Group className="mb-3" controlId="formMFACode">
                            <Form.Label>Enter MFA Code</Form.Label>
                            <VerificationInput
                                numberOfFields={6}
                                onVerify={handleVerifyMFA}
                                loading={loading}
                                clear={clearVerificationInput}
                                message="Please enter the 6-digit code from your authenticator app."
                            />
                        </Form.Group>
                    </Form>
                )}
            </Card.Body>
        </Card>
    );
}

export default Login;