// Import necessary dependencies and components
import React, { useState, useEffect } from 'react';
import api from '../../Component/Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import VerificationInput from '../Common/VerificationInput';

const ChangeEmail = () => {
    // State variables
    const [currentEmail, setCurrentEmail] = useState('****');
    const [newEmail, setNewEmail] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isModeTwo, setIsModeTwo] = useState(false);
    const [isMFAMode, setIsMFAMode] = useState(false);
    const { addToast } = useToast();
    const [clearVerificationInput, setClearVerificationInput] = useState(false);
    const [mfaRequiredMessage, setMfaRequiredMessage] = useState('');

    // Fetch user data on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const response = await api.get('/api/user/profile', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    }
                });

                const { email } = response.data.user;
                setCurrentEmail(email);
            } catch (error) {
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
                addToast(`Error fetching user data: ${errorMessage}`, {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true
                });
            }
        };

        fetchUserData();
    }, [addToast]);

    // Handle edit button click
    const handleEdit = () => {
        setIsEditing(true);
        setNewEmail(currentEmail);
    };

    // Handle cancel button click
    const handleCancel = () => {
        if (isModeTwo) {
            setIsModeTwo(false);
        } else {
            setIsEditing(false);
            setNewEmail('');
        }
    };

    // Handle save button click
    const handleSave = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const mfaToken = localStorage.getItem('mfaToken');
            await api.post('/api/user/profile/change-email-request', { new_email: newEmail }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'x-mfa-token': mfaToken || ''
                }
            });

            addToast('Verification code sent to new email address. Please verify to complete the update.', {
                autoHide: true,
                delay: 15000,
                color: 'info',
            });

            setIsModeTwo(true);
            setIsEditing(false);
            setClearVerificationInput(true);
        } catch (error) {
            if (error.response?.data?.error?.code === 'MFA_REQUIRED') {
                setIsMFAMode(true);
                setMfaRequiredMessage(error.response.data.error.message);
            } else {
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
                addToast(`Error requesting email change: ${errorMessage}`, {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true
                });
            }
        }
    };

    // Handle verification code submission
    const handleVerify = async (code) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const mfaToken = localStorage.getItem('mfaToken');
            await api.post('/api/user/profile/change-email-confirm', { new_email: newEmail, verification_code: code }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'x-mfa-token': mfaToken || '',
                }
            });

            setCurrentEmail(newEmail);
            setIsModeTwo(false);
            setClearVerificationInput(false);

            addToast('Email updated successfully!', {
                autoHide: true,
                delay: 5000,
                color: 'success',
            });
        } catch (error) {
            if (error.response?.data?.error?.code === 'MFA_REQUIRED') {
                setIsMFAMode(true);
                setMfaRequiredMessage(error.response.data.error.message);
            } else {
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
                addToast(`Error verifying email change: ${errorMessage}`, {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true
                });
            }
        }
    };

    // Handle MFA verification
    const handleMFA = async (token) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await api.post('/api/auth/verify-totp', { totpCode: token }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });

            const { mfaToken } = response.data;
            localStorage.setItem('mfaToken', mfaToken);
            setIsMFAMode(false);
            setMfaRequiredMessage('');
            addToast('MFA verified successfully. Please proceed.', {
                autoHide: true,
                delay: 5000,
                color: 'success',
            });
        } catch (error) {
            const errorMessage = error.response?.data?.error?.message || 'Invalid MFA token. Please try again.';
            addToast(`Error verifying MFA: ${errorMessage}`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
        }
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">Change Email</Card.Title>
                <Form onSubmit={handleSubmit}>
                    {isMFAMode ? (
                        // MFA verification mode
                        <>
                            <Form.Group className="mb-3" controlId="formMFA">
                                <Form.Label>Multi-Factor Authentication</Form.Label>
                                <VerificationInput
                                    numberOfFields={6}
                                    onVerify={handleMFA}
                                    clear={clearVerificationInput}
                                    message={mfaRequiredMessage}
                                />
                            </Form.Group>
                            <Button variant="outline-secondary" onClick={() => setIsMFAMode(false)}>
                                <FontAwesomeIcon icon={faTimes} /> Cancel MFA
                            </Button>
                        </>
                    ) : !isModeTwo ? (
                        // Email edit mode
                        <>
                            <Form.Group className="mb-3" controlId="formCurrentEmail">
                                <Form.Label>Current Email Address</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="email"
                                        value={isEditing ? newEmail : currentEmail}
                                        readOnly={!isEditing}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        className={isEditing ? 'editing-mode' : ''}
                                    />
                                    {isEditing ? (
                                        <>
                                            <Button variant="outline-secondary" onClick={handleSave}>
                                                <FontAwesomeIcon icon={faSave} /> Save
                                            </Button>
                                            <Button variant="outline-secondary" onClick={handleCancel}>
                                                <FontAwesomeIcon icon={faTimes} /> Cancel
                                            </Button>
                                        </>
                                    ) : (
                                        <Button variant="outline-secondary" onClick={handleEdit}>
                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                        </Button>
                                    )}
                                </InputGroup>
                            </Form.Group>
                            <div className="text-muted">
                                <Button variant="link" onClick={() => setIsModeTwo(true)}>
                                    Already have a verification code?
                                </Button>
                            </div>
                        </>
                    ) : (
                        // Verification code input mode
                        <>
                            <Form.Group className="mb-3" controlId="formCurrentEmail">
                                <Form.Label>Current Email Address</Form.Label>
                                <Form.Control type="email" value={currentEmail} readOnly />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formNewEmail">
                                <Form.Label>New Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    className='editing-mode'
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formVerificationCode">
                                <Form.Label>Verification Code</Form.Label>
                                <VerificationInput
                                    numberOfFields={4}
                                    onVerify={handleVerify}
                                    clear={clearVerificationInput}
                                />
                            </Form.Group>
                            <Button variant="outline-secondary" onClick={handleCancel}>
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                            </Button>
                        </>
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ChangeEmail;