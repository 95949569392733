// Import axios library for making HTTP requests
import axios from 'axios';

// Create an Axios instance with custom configuration
const api = axios.create({
    // Set the base URL for all requests
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    // Set default headers for all requests
    headers: {
        'x-env': process.env.REACT_APP_ENV
    }
});

// Add interceptors only in development environment
if (process.env.REACT_APP_ENV === 'development') {
    // Request interceptor
    api.interceptors.request.use(request => {
        // Log the outgoing request details
        console.log('Starting Request', JSON.stringify(request, null, 2));
        return request;
    }, error => {
        // Log any errors that occur during the request
        console.log('Request Error:', error);
        return Promise.reject(error);
    });

    // Response interceptor
    api.interceptors.response.use(response => {
        // Log the incoming response details
        console.log('Response:', JSON.stringify(response, null, 2));
        return response;
    }, error => {
        // Log any errors that occur in the response
        console.log('Response Error:', error);
        return Promise.reject(error);
    });
}

// Export the configured Axios instance for use in other parts of the application
export default api;
