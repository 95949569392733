import React, { useState, useEffect } from 'react';
import api from '../../Component/Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { Card, Form, Button } from 'react-bootstrap';
import VerificationInput from '../Common/VerificationInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ConfigureTOTP = () => {
    // State variables
    const [isMFAEnabled, setIsMFAEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [setupUri, setSetupUri] = useState('');
    const [backupCodes, setBackupCodes] = useState([]);
    const [mode, setMode] = useState(0); // 0: initial, 1: QR code setup, 2: show backup codes, 3: MFA requirement
    const { addToast } = useToast();
    const [isMFAMode, setIsMFAMode] = useState(false);
    const [mfaRequiredMessage, setMfaRequiredMessage] = useState('');
    const [clearVerificationInput, setClearVerificationInput] = useState(false);
    const [loadingVerify, setLoadingVerify] = useState(false);

    // Fetch user data on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const response = await api.get('/api/user/profile', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    }
                });

                setIsMFAEnabled(response.data.user.is_mfa_enabled);
                setLoading(false);
            } catch (error) {
                // Handle error and show toast message
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
                addToast(`Error fetching user data: ${errorMessage}`, {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true,
                });
                setLoading(false);
            }
        };

        fetchUserData();
    }, [addToast]);

    // Handle TOTP setup initiation
    const handleEdit = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const mfaToken = localStorage.getItem('mfaToken');

            const response = await api.post('/api/user/profile/totp-setup', {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'x-mfa-token': mfaToken || ''
                },
            });

            setSetupUri(response.data.otpauthUrl);
            setMode(1); // Move to Mode 1: QR Code setup

            addToast('TOTP configuration started! Scan the QR code with your authenticator app to continue.', {
                autoHide: true,
                delay: 5000,
                color: 'info',
            });

        } catch (error) {
            // Handle MFA requirement or show error toast
            if (error.response?.data?.error?.code === 'MFA_REQUIRED') {
                setIsMFAMode(true);
                setMfaRequiredMessage(error.response.data.error.message);
            } else {
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
                addToast(`Error setting up TOTP: ${errorMessage}`, {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true,
                });
            }
        }
    };

    // Handle cancellation of TOTP setup
    const handleCancel = () => {
        setMode(0); // Go back to initial mode
        setSetupUri('');
    };

    // Handle TOTP verification
    const handleVerifyTOTP = async (token) => {
        try {
            setLoadingVerify(true);
            const accessToken = localStorage.getItem('accessToken');
            const mfaToken = localStorage.getItem('mfaToken');
            const response = await api.post('/api/user/profile/totp-setup-verify', { token }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'x-mfa-token': mfaToken || '',
                },
            });

            setBackupCodes(response.data.backupCodes);
            setIsMFAEnabled(true);
            setMode(2); // Move to show backup codes

            addToast('TOTP configured successfully! Save your backup codes.', {
                autoHide: true,
                delay: 5000,
                color: 'success',
            });
        } catch (error) {
            // Handle error and show toast message
            const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
            addToast(`Error verifying TOTP: ${errorMessage}`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true,
            });
        } finally {
            setLoadingVerify(false);
        }
    };

    // Handle MFA verification
    const handleMFA = async (token) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await api.post('/api/auth/verify-totp', { totpCode: token }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });

            const { mfaToken } = response.data;
            localStorage.setItem('mfaToken', mfaToken);
            setIsMFAMode(false);
            setMfaRequiredMessage('');

            addToast('MFA verified successfully. Please proceed.', {
                autoHide: true,
                delay: 5000,
                color: 'success',
            });

            // Retry TOTP setup after MFA verification
            handleEdit();
        } catch (error) {
            // Handle error and show toast message
            const errorMessage = error.response?.data?.error?.message || 'Invalid MFA token. Please try again.';
            addToast(`Error verifying MFA: ${errorMessage}`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true
            });
        }
    };

    // Render component
    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">Configure Multi-Factor Authentication (MFA)</Card.Title>
                {/* Initial mode: Display MFA status and setup button */}
                {mode === 0 && (
                    <>
                        {/* MFA status display */}
                        <Form.Group className="mb-3" controlId="formMFAStatus">
                            <Form.Label>Current MFA Setup Status</Form.Label>
                            <Form.Control
                                type="text"
                                value={loading ? '' : isMFAEnabled ? 'Enabled' : 'Disabled'}
                                readOnly
                                className={isMFAEnabled ? 'text-success' : 'text-danger'}
                            />
                        </Form.Group>
                        <p>{`Multi-Factor Authentication (MFA) adds an extra layer of security to your account. We use 
                        TOTP (Time-Based One-Time Password) for MFA because it is more secure than email or SMS-based methods.`}</p>
                        <p className="mb-4">{isMFAEnabled
                            ? 'MFA is enabled on your account. You cannot disable MFA, but you can transfer it to a new device if needed.'
                            : 'Click the button below to start the process of enabling Multi-Factor Authentication (MFA).'}
                        </p>
                        {/* MFA setup or transfer button */}
                        <Button variant="outline-secondary" className="mb-3" onClick={handleEdit}>
                            {isMFAEnabled ? 'Transfer TOTP to a New Device' : 'Set up TOTP'}
                        </Button>
                    </>
                )}

                {/* MFA verification mode */}
                {isMFAMode && (
                    <>
                        {/* MFA verification input */}
                        <Form.Group className="mb-3" controlId="formMFA">
                            <Form.Label>Multi-Factor Authentication Required</Form.Label>
                            <VerificationInput
                                numberOfFields={6}
                                onVerify={handleMFA}
                                clear={clearVerificationInput}
                                message={mfaRequiredMessage}
                            />
                        </Form.Group>
                        {/* Cancel MFA button */}
                        <Button variant="outline-secondary" className="mt-3" onClick={() => setIsMFAMode(false)}>
                            <FontAwesomeIcon icon={faTimes} /> Cancel MFA
                        </Button>
                    </>
                )}

                {/* QR code setup mode */}
                {mode === 1 && (
                    <>
                        {/* QR code display */}
                        <div className="mt-4">
                            <p>Scan the QR code with your authenticator app:</p>
                            <img
                                src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(setupUri)}`}
                                alt="QR Code"
                                className="mb-4"
                            />
                        </div>

                        {/* TOTP verification input */}
                        <Form.Group className="mt-4" controlId="formVerificationCode">
                            <Form.Label>Enter TOTP Code</Form.Label>
                            <VerificationInput
                                numberOfFields={6}
                                onVerify={handleVerifyTOTP}
                                clear={clearVerificationInput}
                            />
                        </Form.Group>
                        {/* Cancel setup button */}
                        <Button variant="outline-secondary" className="mt-3" onClick={handleCancel}>
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                        </Button>
                    </>
                )}

                {/* Backup codes display mode */}
                {mode === 2 && (
                    <div className="mt-4">
                        <h5>Backup Codes</h5>
                        <p>Save these codes in a secure place. You will need them if you cannot access your
                            authenticator app.</p>
                        <ul className="mb-4">
                            {backupCodes.map((code, idx) => (
                                <li key={idx}>{code}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default ConfigureTOTP;