// Import necessary dependencies
import React, {useState, useEffect} from 'react';
import {useAuth} from '../../Contexts/AuthContext';
import SignOut from '../Auth/SignOut';
import {useToast} from '../../Contexts/ToastContext';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faSignInAlt, faHomeAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import LogoBlack from "../../assets/images/base_logo_transparent_background.svg";
import LogoWhite from "../../assets/images/base_logo_transparent_background.svg";
import SmallLogoBlack from "../../assets/images/base_icon_transparent_background.svg";
import SmallLogoWhite from "../../assets/images/base_icon_transparent_background.svg";

// Create a map of icons for easy access
const icons = {
    "faUser": faUser,
    "faSignInAlt": faSignInAlt,
    "faHomeAlt": faHomeAlt,
    "faSignOutAlt": faSignOutAlt
};

// Sidebar component
function Sidebar(props) {
    // Destructure props
    const {activekey, menuData, GotoChangeMenu, initIsSidebarMini} = props;
    // Get authentication context
    const {isAuthenticated, authCheckComplete, authenticatedEmail} = useAuth();
    // Get toast context
    const {addToast} = useToast();
    // State to manage sidebar mini mode
    const [isSidebarMini, setIsSidebarMini] = useState(initIsSidebarMini);

    // Function to toggle sidebar mini mode
    const ChangeSideBar = (setVal) => {
        localStorage.setItem("isSideBarMini", !setVal);
        setIsSidebarMini(!setVal)
    }

    // Hook for navigation
    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;

    // Function to get the appropriate logo based on theme and sidebar mode
    const getLogo = () => {
        const mode = document.documentElement.getAttribute("data-theme");
        if (isSidebarMini) {
            return mode === 'light' ? SmallLogoBlack : SmallLogoWhite;
        } else {
            return mode === 'light' ? LogoBlack : LogoWhite;
        }
    }

    // Filter menu data based on authentication status
    const filteredMenuData = menuData.filter(item =>
        item.authenticated === null ||
        item.authenticated === isAuthenticated
    );

    return (
        <div id="mainsidemenu"
             className={`sidebar py-2 py-md-2 me-0 border-end ${isSidebarMini === true ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                {/* Logo/Home link */}
                <Link to="/" className="mb-0 brand-icon">
                    <span className="logo-icon">
                        <img className="logo-image" src={getLogo()} alt=""/>
                    </span>
                </Link>

                {/* Custom separator */}
                <hr className="custom-separator" />

                {/* Menu items */}
                <ul className={`menu-list flex-grow-1 ${isSidebarMini ? 'mt-5' : 'mt-4'} px-1`}>
                    {authCheckComplete && filteredMenuData.map((item, i) => (
                        <li key={i}>
                            <Link to={"/" + item.routerLink[0]}
                                  className={`m-link ${("/" + item.routerLink[0] === activekey) ? "active" : ""}`}>
                                <FontAwesomeIcon icon={icons[item.icon]} className="me-2"/>
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                    {/* Sign out option for authenticated users */}
                    {isAuthenticated && <SignOut />}
                </ul>
                {/* Button to toggle sidebar mini mode */}
                <button type="button" className="btn btn-link sidebar-mini-btn text-muted" onClick={() => {
                    ChangeSideBar(isSidebarMini)
                }}>
                    <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>
    )
}

export default Sidebar;