import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../Common/axiosSetup';

function Verification() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [tokenExpired, setTokenExpired] = useState(false);
    const [email, setEmail] = useState('');
    const [resendSuccess, setResendSuccess] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    // Helper function to get the query parameter from the URL
    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    const resendVerificationEmail = async () => {
        try {
            setLoading(true);
            setError('');
            setMessage('');  // Clear any previous message
            setResendSuccess(false);  // Reset resend state

            const response = await api.post('/api/auth/resend-verification', { email });
            setMessage('A new verification email has been sent to your inbox. Please check it to complete the verification process.');
            setResendSuccess(true);  // Show resend success message
        } catch (error) {
            const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred while resending the email.';
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const verifyEmail = async () => {
            const token = getQueryParam('token');

            if (!token) {
                setError('Verification token is missing.');
                setLoading(false);
                return;
            }

            try {
                // Send verification request
                const response = await api.post('/api/auth/verify-email', { token });

                // Handle success response
                setSuccess(true);
                setMessage('Email verification successful! You can now log in.');

                // Optionally, redirect to login page after some time
                setTimeout(() => {
                    navigate('/login');
                }, 5000);

            } catch (error) {
                // Handle errors from the API
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred. Contact support@fieldwarden.io if this error persists.';
                setError(errorMessage);

                // Check if the token has expired
                const isTokenExpired = error.response?.data?.error?.tokenExpired;
                const userEmail = error.response?.data?.error?.email;

                if (isTokenExpired) {
                    setTokenExpired(true); // Set tokenExpired to true
                    setEmail(userEmail); // Save the email for resending
                }
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [location, navigate]);

    return (
        <div className="body d-flex p-0 p-xl-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="d-flex flex-column align-items-start">
                            <h1 className="mb-4">Verifying Email...</h1>

                            {loading ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="spinner-border" role="status" aria-hidden="true"></span>
                                    <span className="ms-2">Please wait while we verify your email...</span>
                                </div>
                            ) : success ? (
                                <div className="alert alert-success mt-4">
                                    {message}
                                    <br />
                                    <br />
                                    Redirecting you to login in 5 seconds...
                                </div>
                            ) : (
                                <div className="alert alert-danger mt-4">
                                    {error}
                                    <br />
                                    {tokenExpired && (
                                        <>
                                            <p>Your verification token has expired.</p>
                                            <p>You will need to resend the verification email.</p>
                                            <button
                                                className="btn btn-primary mt-3"
                                                onClick={resendVerificationEmail}
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        Sending...
                                                    </>
                                                ) : (
                                                    'Resend Verification Email'
                                                )}
                                            </button>
                                            {resendSuccess && (
                                                <div className="alert alert-success mt-3">
                                                    A new verification email has been sent. Please check your inbox.
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Verification;