import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../Common/axiosSetup';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [isReset, setIsReset] = useState(false); // To track if we're on the reset password page

    const location = useLocation();
    const navigate = useNavigate();

    // Check the URL path and set the page mode
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('token');
        setToken(token);

        // Normalize the pathname check for reset-password to handle both with and without trailing slashes
        if (location.pathname.includes('/reset-password')) {
            setIsReset(true);
            if (!token) {
                setError('No token provided. Please check your reset link.');
            }
        } else {
            setIsReset(false);
        }
    }, [location]);

    const handleSubmitForgotPassword = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess(false);
        setMessage('');
        setLoading(true);

        try {
            const response = await api.post(`/api/auth/forgot-password-send`, { email });
            setSuccess(true);
            setMessage('Password reset link sent! Please check your email.');
        } catch (error) {
            const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred. Contact support@fieldwarden.io if this error persists.';
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitResetPassword = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess(false);
        setMessage('');
        setLoading(true);

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        if (!token) {
            setError('Reset token is missing. Please try again.');
            setLoading(false);
            return;
        }

        try {
            const response = await api.post(`/api/auth/forgot-password-verify`, {
                token,
                email,
                newPassword: password
            });

            setSuccess(true);
            setMessage('Password reset successful! Redirecting to login...');
            setTimeout(() => navigate('/login'), 5000); // Redirect after 5 seconds
        } catch (error) {
            const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred. Contact support@fieldwarden.io if this error persists.';
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="body d-flex p-0 p-xl-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="d-flex flex-column align-items-start">
                            <h1 className="mb-4">{isReset ? 'Reset Your Password' : 'Forgot Your Password?'}</h1>
                            <h5>{isReset ? 'Enter your email and new password' : 'Request a password reset link'}</h5>
                            <span className="text-muted">
                                {isReset ? 'No worries! Set a new password to regain access to your account.' : 'Enter your email to receive a password reset link.'}
                            </span>

                            {success ? (
                                <div className="alert alert-success mt-4">{message}</div>
                            ) : (
                                <div className="card mt-4 w-100">
                                    <div className="card-body p-4">
                                        {error && <div className="alert alert-danger pre-wrap">{error}</div>}
                                        <form onSubmit={isReset ? handleSubmitResetPassword : handleSubmitForgotPassword}>
                                            {/* Common for both forms: Email */}
                                            <div className="mb-3">
                                                <label className="form-label fs-6">Email Address *</label>
                                                <input type="email" className="form-control"
                                                       value={email} onChange={e => setEmail(e.target.value)} required />
                                            </div>

                                            {/* Show password fields only if it's the reset password page */}
                                            {isReset && (
                                                <>
                                                    <div className="mb-3">
                                                        <label className="form-label fs-6">New Password *</label>
                                                        <input type="password" className="form-control"
                                                               value={password} onChange={e => setPassword(e.target.value)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label fs-6">Confirm New Password *</label>
                                                        <input type="password" className="form-control"
                                                               value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
                                                    </div>
                                                </>
                                            )}

                                            <button type="submit" disabled={loading}
                                                    className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                                                {loading ? (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm me-2"
                                                              role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </>
                                                ) : (
                                                    isReset ? 'Reset Password' : 'Send Reset Link'
                                                )}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;