import React, { useState } from "react";
import { Link } from "react-router-dom";
import api from '../Common/axiosSetup';

function Register() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess(false);
        setMessage('');
        setLoading(true);

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            const response = await api.post(`/api/auth/register`, {
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phoneNumber,
                password
            });
            setSuccess(true);
            setMessage('Registration successful! Please check your email to verify your account.');
        } catch (error) {
            if (error.response) {
                const { status } = error.response;
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred. Contact support@fieldwarden.io if this error persists.';

                if (status === 404) {
                    setError('Service currently unavailable. Please try again later.');
                } else if (status >= 500) {
                    setError('Registration failed. Please fix the error below and try again.\n\n' + errorMessage || 'Registration failed. Please try again.\n\nUnknown Error. Please contact support@fieldwarden.io if this error persists.');
                } else {
                    setError('Registration failed. Please fix the error below and try again.\n\n' + errorMessage || 'Registration failed. Please try again.\n\nUnknown Error. Please contact support@fieldwarden.io if this error persists.');
                }
            } else if (error.request) {
                setError('No response from the server. Please check your network connection. Contact support@fieldwarden.io if this error persists.');
            } else {
                setError('Error: ' + error.message + '\n\nPlease contact support@fieldwarden.io if this error persists.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Get Started with Field Warden!</h1>
                                <h5>Create Your Account & Verify Your Email</h5>
                                <span className="text-muted">Sign up today and verify your email to gain full access to Field Warden's all-in-one platform. Simplify your daily operations with innovative tools built for the oil and gas industry. From safety to productivity, Field Warden helps you streamline workflows and stay compliant, empowering your team to operate with confidence.</span>
                                <span className="text-decoration-underline mt-4">Welcome to Field Warden—where operational excellence meets cutting-edge technology!</span>
                                {success ? (
                                    <div className="alert alert-success mt-4">{message}</div>
                                ) : (
                                    <div className="card mt-4 w-100">
                                        <div className="card-body p-4">
                                            {error && <div className="alert alert-danger pre-wrap">{error}</div>}
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label fs-6">First Name *</label>
                                                    <input type="text" className="form-control"
                                                           value={firstName} onChange={e => setFirstName(e.target.value)} required/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fs-6">Last Name *</label>
                                                    <input type="text" className="form-control"
                                                           value={lastName} onChange={e => setLastName(e.target.value)} required/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fs-6">Email Address *</label>
                                                    <input type="email" className="form-control"
                                                           value={email} onChange={e => setEmail(e.target.value)} required/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fs-6">Phone Number</label>
                                                    <input type="text" className="form-control"
                                                           value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fs-6">Password *</label>
                                                    <input type="password" className="form-control"
                                                           value={password} onChange={e => setPassword(e.target.value)} required/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fs-6">Confirm Password *</label>
                                                    <input type="password" className="form-control"
                                                           value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required/>
                                                </div>
                                                <button type="submit" disabled={loading}
                                                        className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                                                    {loading ? (
                                                        <>
                                                        <span className="spinner-border spinner-border-sm me-2"
                                                              role="status" aria-hidden="true"></span>
                                                            Loading...
                                                        </>
                                                    ) : (
                                                        "Register"
                                                    )}
                                                </button>
                                            </form>
                                            <Link to={process.env.PUBLIC_URL + "/login"} className="d-block mt-3">
                                                Already registered? <span className="text-secondary text-decoration-underline">Login</span>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
