// Import necessary dependencies and components
import React, {useState, useEffect, useRef} from 'react';
import api from '../../Component/Common/axiosSetup';
import {useToast} from '../../Contexts/ToastContext';
import {Card, Form, Button, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';

// UserProfileOverview component
const UserProfileOverview = () => {
    // State variables for user profile data
    const [userId, setUserId] = useState('****');
    const [userEmail, setUserEmail] = useState('****');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const {addToast} = useToast();
    const isMounted = useRef(false);

    // Function to format phone number
    const formatPhoneNumber = (phone) => {
        const sanitized = phone ? phone.replace(/\D/g, '') : null;
        if (!sanitized) return phone;

        let formattedNumber = sanitized;
        if (sanitized.length === 10) {
            formattedNumber = '1' + sanitized;
        }

        if (formattedNumber.length >= 11) {
            const countryCode = formattedNumber[0];
            const areaCode = formattedNumber.slice(1, 4);
            const firstPart = formattedNumber.slice(4, 7);
            const secondPart = formattedNumber.slice(7, 11);
            return `${countryCode} (${areaCode}) ${firstPart} ${secondPart}`;
        }

        return phone;
    };

    // Effect hook to fetch user data on component mount
    useEffect(() => {
        if (isMounted.current) return;
        isMounted.current = true;

        const fetchUserData = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');

                const response = await api.get('/api/user/profile', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                const {id, email, first_name, last_name, phone_number, is_verified} = response.data.user;

                // Set user data in state
                setUserId(id || '****');
                setUserEmail(email || '****');
                setUserFirstName(first_name || '');
                setUserLastName(last_name || '');
                setPhoneNumber(formatPhoneNumber(phone_number || ''));
                setIsEmailVerified(is_verified);
            } catch (error) {
                const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
                addToast(`Error fetching user data: ${errorMessage}`, {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true,
                });
            }
        };

        fetchUserData();
    }, [addToast]);

    // Function to handle edit button click
    const handleEdit = () => {
        setIsEditing(true);
    };

    // Function to handle save button click
    const handleSave = async () => {
        try {
            const sanitizedPhoneNumber = formatPhoneNumber(phoneNumber);

            const accessToken = localStorage.getItem('accessToken');

            // Send updated user data to the server
            await api.patch('/api/user/profile', {
                first_name: userFirstName,
                last_name: userLastName,
                phone_number: sanitizedPhoneNumber,
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });

            setIsEditing(false);
            addToast('Profile updated successfully!', {
                autoHide: true,
                delay: 5000,
                color: 'success',
            });
        } catch (error) {
            const errorMessage = error.response?.data?.error?.message || 'An unexpected error occurred.';
            addToast(`Error modifying user data: ${errorMessage}`, {
                autoHide: true,
                delay: 15000,
                color: 'danger',
                requireAck: true,
            });
        }
    };

    // Function to handle cancel button click
    const handleCancel = () => {
        setIsEditing(false);
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    // Render the component
    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">User Profile Overview</Card.Title>
                <Form onSubmit={handleSubmit}>
                    {/* User ID field */}
                    <Form.Group className="mb-3" controlId="formUserId">
                        <Form.Label>User ID</Form.Label>
                        <Form.Control type="text" value={userId} readOnly/>
                    </Form.Group>
                    {/* Email address field */}
                    <Form.Group className="mb-3" controlId="formUserEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" value={userEmail} readOnly/>
                        <Form.Text className="text-muted">
                            {isEmailVerified ? 'Email is verified' : 'Email is not verified'}
                        </Form.Text>
                    </Form.Group>
                    {/* First name field */}
                    <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={userFirstName}
                            readOnly={!isEditing}
                            onChange={(e) => setUserFirstName(e.target.value)}
                            className={isEditing ? 'editing-mode' : ''}
                        />
                    </Form.Group>
                    {/* Last name field */}
                    <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={userLastName}
                            readOnly={!isEditing}
                            onChange={(e) => setUserLastName(e.target.value)}
                            className={isEditing ? 'editing-mode' : ''}
                        />
                    </Form.Group>
                    {/* Phone number field */}
                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            value={phoneNumber}
                            readOnly={!isEditing}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className={isEditing ? 'editing-mode' : ''}
                        />
                    </Form.Group>
                    {/* Edit/Save/Cancel buttons */}
                    <InputGroup>
                        {isEditing ? (
                            <>
                                <Button variant="outline-secondary" onClick={handleSave}>
                                    <FontAwesomeIcon icon={faSave}/> Save
                                </Button>
                                <Button variant="outline-secondary" onClick={handleCancel}>
                                    <FontAwesomeIcon icon={faTimes}/> Cancel
                                </Button>
                            </>
                        ) : (
                            <Button variant="outline-secondary" onClick={handleEdit}>
                                <FontAwesomeIcon icon={faEdit}/> Edit
                            </Button>
                        )}
                    </InputGroup>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default UserProfileOverview;